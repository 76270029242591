import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { store } from '@redux/store'
import {
  applePayEnabled,
  applePaySupported,
  onlyNumbers,
  scrollTo,
  getApplePayTotal,
  getApplePayCartTotal,
} from '@helpers/checkout/payment-section/apple-pay'
import { addToCart } from '@helpers/cart'
import { createOrder, updateLineItems, updateAddress, updateDelivery, updatePayment } from '@services/checkout'
import { setOrder, setCheckoutStep } from '@redux/modules/checkout'
import { sentryLogger, levels, sentryMessages, setExtra, cleanOrderObject } from '@helpers/sentry-logger'
import { addToDataLayer, checkoutStepAnalytics } from '@helpers/google-tag-manager'
import { getAddressSpecificBody, checkShippingZip } from '@helpers/checkout/shipping-section'
import { getDeliverySpecificBody, setDeliveryCalendar } from '@helpers/checkout/delivery-section'
import { getOrder, getLineItems } from '@helpers/checkout/global'
import { getCurrentLocation } from '@helpers/geo-location'
import { getFromBrowserStorage } from '@helpers/storage'
import { setCheckoutReviewStep } from '@helpers/checkout/local-storage'
import { ApplePayButton as ApplePay } from '@rtgdev/design-system'
import loaderDark from '../../../../assets/images/loader-dark.svg'
import '@assets/css/components/shared/apple-pay.sass'

const ApplePayButton = (
  { isPDP, isExpress, product, price, activeAddons, warrantyEnabled, componentPage, type },
  ref,
) => {
  const [clicked, setClicked] = useState(false)
  const [showApplePay, setShowApplePay] = useState(false)
  const [orderTotal, setOrderTotal] = useState(0)
  const [cartTotal, setCartTotal] = useState(0)

  useEffect(() => {
    setOrderTotal(getApplePayTotal())
    setCartTotal(getApplePayCartTotal())
    setShowApplePay(applePaySupported() && applePayEnabled())
  }, [])

  useImperativeHandle(ref, () => ({
    handleClick,
  }))

  const checkOrderId = async () => {
    let order = getFromBrowserStorage('session', 'order')
    if (!order.orderId || order.orderId === undefined) {
      const location = getCurrentLocation()
      const lineItems = getLineItems()
      await createOrder({
        lineItems,
        region: location.region,
        zone: parseInt(location.price_zone),
        distribution_index: parseInt(location.distribution_index),
      })
        .then(response => response)
        .then(newOrder => {
          store.dispatch(setOrder(newOrder))
          order = newOrder
        })
    }
    return order
  }

  const updateCartItems = async order => {
    const lineItems = getLineItems()
    const storeCartLineItems = order.lineItems.filter(lineItem => lineItem.isStoreSku)
    const updateOrder = await updateLineItems({
      orderId: order.orderId,
      lineItems: [...lineItems, ...storeCartLineItems],
    })
    store.dispatch(setOrder(updateOrder))
  }

  const addToCartPdp = () => {
    addToDataLayer('click', componentPage, 'add to cart', product.sku)
    addToCart(product, price, activeAddons, warrantyEnabled)
    const viewCartModal = document.getElementsByClassName('view-cart')[0]
    if (viewCartModal) {
      viewCartModal.focus()
    }
  }

  const handleClick = () => {
    const order = getOrder()
    let storeCart = false
    if (order && order.lineItems) {
      order.lineItems.forEach(item => {
        if (item.isStoreSku) storeCart = true
      })
    }
    if (isPDP) {
      addToCartPdp(order, storeCart)
      createPaySession(order, storeCart)
    } else {
      createPaySession(order, storeCart)
    }
  }

  const createPaySession = (pOrder, storeCart) => {
    const rtgOrder = `Roomstogo Furniture Order${isPDP ? ' Subtotal' : ''}`

    let order = pOrder
    let remainingBalance = onlyNumbers(getApplePayTotal() || orderTotal || order.total)

    if (remainingBalance === 0 || isPDP) {
      remainingBalance = onlyNumbers(getApplePayCartTotal() || cartTotal || order.total)
    }

    const paymentRequest = {
      countryCode: 'US',
      currencyCode: 'USD',
      supportedNetworks: ['visa', 'masterCard', 'amex'],
      merchantCapabilities: ['supports3DS'],
      total: { label: rtgOrder, amount: remainingBalance },
      requiredBillingContactFields: ['postalAddress'],
    }

    if (!storeCart && isExpress) {
      paymentRequest.requiredShippingContactFields = ['postalAddress', 'email', 'phone'] // if pdp, fullCart page
    }

    const session = new window.ApplePaySession(1, paymentRequest)
    session.onvalidatemerchant = event => {
      const { validationURL } = event
      addToDataLayer('click', 'cart', 'apple pay')
      setClicked(true)

      fetch(`${process.env.GATSBY_APPLEPAY_URL}/merchantSession`, {
        body: JSON.stringify({
          url: validationURL,
          initiativeContext: window.location.hostname,
          storeCart,
        }),
        headers: {
          'Content-Type': 'application/json',
        },
        mode: 'cors',
        method: 'POST',
      })
        .then(response => response.json())
        .then(async json => {
          session.completeMerchantValidation(json)
          if (isPDP) {
            order = await checkOrderId() // check if orderId is available or createOrder
            updateCartItems(order, storeCart) // update order cart line items
          }
        })
        .catch(error => {
          sentryLogger({
            configureScope: {
              type: setExtra,
              level: levels.fatal,
              order: cleanOrderObject(order),
              paymentType: 'APP',
            },
            captureMessage: {
              type: 'text',
              message: `Unable to validate merchantSession - ${error}`,
              level: levels.fatal,
            },
          })
          // eslint-disable-next-line no-alert
          alert("We're unable to process this payment, please try again.")
          setClicked(false)
        })
    }

    session.onpaymentauthorized = async event => {
      const { payment } = event

      const sc = payment.shippingContact
      const bc = payment.billingContact
      const tok = payment.token

      order.selectedPaymentType = 'Apple Pay'
      order.payer = {
        ...order.payer,
        firstName: bc.givenName,
        lastName: bc.familyName,
        email: !storeCart && isExpress ? sc.emailAddress : order.contact.email,
        phone: !storeCart && isExpress ? sc.phoneNumber : order.contact.phone,
      }
      order.billingAddress = {
        ...order.billingAddress,
        address1: bc.addressLines[0],
        address2: bc.addressLines[1] ? bc.addressLines[1] : '',
        city: bc.locality,
        state: bc.administrativeArea,
        zip: bc.postalCode,
      }

      if (!storeCart && isExpress) {
        order.contact = {
          ...order.contact,
          firstName: sc.givenName,
          lastName: sc.familyName,
          email: sc.emailAddress,
          phone: sc.phoneNumber,
        }
        order.shippingAddress = {
          ...order.shippingAddress,
          address1: sc.addressLines[0],
          address2: sc.addressLines[1] ? bc.addressLines[1] : '',
          city: sc.locality,
          state: sc.administrativeArea,
          zip: sc.postalCode,
        }
      }

      store.dispatch(setOrder(order))

      if (isExpress) {
        let validState = true
        validState = await checkShippingZip(!storeCart && isExpress ? sc.postalCode : order.shippingAddress.zip).catch(
          () => {
            validState = false
          },
        )
        if (validState) {
          const addrOrder = await updateAddress(getAddressSpecificBody(order, true)).catch(() => {
            setClicked(false)
            sentryLogger({
              configureScope: {
                type: setExtra,
                level: levels.error,
                orderId: order.orderId,
                section: 'Checkout: Payment Section - Apple Pay',
              },
              captureMessage: {
                level: levels.error,
                message: sentryMessages.updateAddressFailure,
              },
            })
          })
          if (addrOrder.deliveryCalendar.length > 0) {
            const calendar = setDeliveryCalendar(
              addrOrder.deliveryCalendar,
              addrOrder.pickupCalendar,
              addrOrder.expressCalendar,
              false,
            )
            addrOrder.deliveryDate =
              calendar.filter(date => date.isStandardDelivery && !date.isExpressDelivery)[0].date || calendar[0].date
          }
          updateDelivery(getDeliverySpecificBody(addrOrder))
            .then(() => {
              updatePayment({
                paymentInfo: [
                  {
                    paymentType: 'APP',
                    paymentProperties: {
                      paymentData: tok.paymentData,
                      paymentMethod: tok.paymentMethod,
                      transactionIdentifier: tok.transactionIdentifier,
                    },
                  },
                ],
                orderId: order.orderId,
              })
                .then(paidOrder => {
                  session.completePayment(window.ApplePaySession.STATUS_SUCCESS)
                  store.dispatch(setOrder(paidOrder))
                  store.dispatch(setCheckoutStep('review'))
                  checkoutStepAnalytics('review')
                  setCheckoutReviewStep()
                  setTimeout(() => {
                    // TODO: navigation need to add code to track ga data

                    navigate('/checkout')
                  }, 250)
                  scrollTo('review')
                })
                .catch(error => {
                  session.completePayment(window.ApplePaySession.STATUS_FAILURE)
                  sentryLogger({
                    configureScope: {
                      type: setExtra,
                      level: levels.fatal,
                      order: cleanOrderObject(order),
                      paymentType: 'APP',
                    },
                    captureMessage: {
                      type: 'text',
                      message: `${sentryMessages.paymentUpdateFailure} - ${error}`,
                      level: levels.fatal,
                    },
                  })
                  // eslint-disable-next-line no-alert
                  alert("We're unable to process this payment, please try again.")
                  setClicked(false)
                })
            })
            .catch(() => {
              sentryLogger({
                configureScope: {
                  type: setExtra,
                  level: levels.error,
                  deliveryDate: order.deliveryDate,
                  doorwayDelivery: order.doorwayDelivery,
                  orderId: order.orderId,
                },
                captureMessage: {
                  type: 'text',
                  message: sentryMessages.updateDeliveryFailure,
                  level: levels.error,
                },
              })
            })
        } else if (window) {
          window.location.reload()
        }
      } else {
        updatePayment({
          paymentInfo: [
            {
              paymentType: 'APP',
              paymentProperties: {
                paymentData: tok.paymentData,
                paymentMethod: tok.paymentMethod,
                transactionIdentifier: tok.transactionIdentifier,
              },
            },
          ],
          orderId: order.orderId,
        })
          .then(paidOrder => {
            session.completePayment(window.ApplePaySession.STATUS_SUCCESS)
            setClicked(false)
            store.dispatch(setOrder(paidOrder))
            store.dispatch(setCheckoutStep('review'))
            checkoutStepAnalytics('review')
            setCheckoutReviewStep()
            scrollTo('payment')

            updateAddress(getAddressSpecificBody(paidOrder, true)).catch(() => {
              sentryLogger({
                configureScope: {
                  type: setExtra,
                  level: levels.error,
                  orderId: order.orderId,
                  section: 'Checkout: Payment Section - Apple Pay',
                },
                captureMessage: {
                  level: levels.error,
                  message: sentryMessages.updateAddressFailure,
                },
              })
            })
          })
          .catch(error => {
            session.completePayment(window.ApplePaySession.STATUS_FAILURE)
            sentryLogger({
              configureScope: {
                type: setExtra,
                level: levels.fatal,
                order: cleanOrderObject(order),
                paymentType: 'APP',
              },
              captureMessage: {
                type: 'text',
                message: `${sentryMessages.paymentUpdateFailure} - ${error}`,
                level: levels.fatal,
              },
            })
            // eslint-disable-next-line no-alert
            alert("We're unable to process this payment, please try again.")
            setClicked(false)
          })
      }
    }

    // eslint-disable-next-line no-unused-vars
    session.oncancel = event => {
      // alert("We're unable to proccess this payment, please try again.")
      setClicked(false)
    }

    session.begin()
  }

  return (
    <>
      {showApplePay && !clicked && (
        <ApplePay buttonStyle="black" type={type} onClick={() => handleClick()} onKeyDown={() => handleClick()} />
      )}
      {clicked && <img className="loader applepay-button-wrapper" alt="Clicked Apple Pay Button" src={loaderDark} />}
    </>
  )
}

ApplePayButton.propTypes = {
  activeAddons: PropTypes.any,
  componentPage: PropTypes.any,
  isExpress: PropTypes.any,
  isPDP: PropTypes.any,
  price: PropTypes.any,
  product: PropTypes.shape({
    sku: PropTypes.any,
  }),
  type: PropTypes.any,
  warrantyEnabled: PropTypes.any,
}

export default forwardRef(ApplePayButton)
