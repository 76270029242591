import { getCart, getCartTotal, getStoreCartTotal } from '@helpers/cart'
import { getOrder } from '../global'
import { getRemainingTotal } from './payment-section'

export const onlyNumbers = value => {
  if (value) {
    return value
      .toString()
      .trim()
      .replace(/\$/g, '') // replace all $
      .replace(/,/g, '') // remove all commas/extra char for Apple Pay
  }
  return value
}

export const applePaySupported = () =>
  typeof window !== 'undefined' &&
  window.location.protocol === 'https:' &&
  window.ApplePaySession &&
  window.ApplePaySession.canMakePayments()

export const applePayEnabled = async () => {
  if (typeof window !== 'undefined' && window.location.protocol === 'https:' && window.ApplePaySession) {
    const promise = window.ApplePaySession.canMakePaymentsWithActiveCard(process.env.GATSBY_APPLE_MERCHANT_IDENTIFIER)
    return promise.then(canMakePayments => canMakePayments)
  }
  return false
}

export const getApplePayEmail = order => {
  if (order && order.paymentInfo) {
    const pp = order.paymentInfo.filter(payment => payment.paymentType === 'APP')[0]
    if (pp && pp.paymentProperties && pp.paymentProperties.payerEmail) {
      return pp.paymentProperties.payerEmail
    }
  }
  return ''
}

export const getApplePayTotal = total => {
  const order = getOrder()
  let apTotal = order.total
  if (order && order.orderId && (total > 0 || order.total > 0)) {
    const applyFinance =
      order &&
      order.paymentInfo &&
      order.paymentInfo.filter(
        payment =>
          payment.paymentType === 'FIN' ||
          (payment.paymentType === 'DBUY' && payment.paymentProperties && payment.paymentProperties.hasPayments),
      ).length > 0
    apTotal = Math.floor((getRemainingTotal(true, null, true, applyFinance).total || total) * 100) / 100
  }
  return apTotal
}

export const generateShippingAddressLineTwo = ({ shippingAddress, billingAddress }) => {
  let addressLineTwo = ''
  if (shippingAddress && shippingAddress.address_line_2) {
    addressLineTwo = shippingAddress.address_line_2
  } else if (billingAddress && billingAddress.address_line_2) {
    addressLineTwo = billingAddress.address_line_2
  }
  return addressLineTwo
}

export const getApplePayCartTotal = () => {
  const cart = getCart()
  const order = getOrder()
  let cartTotal = getCartTotal(cart)
  if (order && order.lineItems) {
    const storeCartLineItems = order.lineItems.filter(lineItem => lineItem.isStoreSku)
    if (storeCartLineItems.length > 0) {
      cartTotal += getStoreCartTotal(storeCartLineItems)
    }
  }
  cartTotal = Math.floor(cartTotal * 100) / 100
  return cartTotal
}

export const scrollTo = element => {
  setTimeout(() => {
    if (document.getElementById(element)) {
      window.scroll({
        top:
          document.getElementById(element)?.offsetTop - 25 - document.getElementsByClassName('head')?.[0]?.offsetHeight,
      })
    }
  }, 1000)
}

export const triggerEvent = (el, type) => {
  if ('createEvent' in document) {
    // modern browsers, IE9+
    const e = document.createEvent('HTMLEvents')
    e.initEvent(type, false, true)
    el.dispatchEvent(e)
  } else {
    // IE8-, etc
    const e = document.createEventObject()
    e.eventType = type
    el.fireEvent(`on${e.eventType}`, e)
  }
}
